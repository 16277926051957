import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import style from './Loader.module.css';

const Loader = ({ staticHeight }) => (
  <div
    className={classnames(style.container, {
      [style.staticHeight]: staticHeight,
    })}
  >
    <div className={style.ring}>
      <div className={style.innerRing} />
    </div>
  </div>
);

Loader.propTypes = {
  staticHeight: PropTypes.bool,
};

export default Loader;
